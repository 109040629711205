import * as _ from "lodash";
import { usePermissions } from "react-admin";
import React, { useState, useEffect, useRef, useCallback, FC } from "react";

export const useMe = () => {
  const { loading, permissions } = usePermissions();

  return { loading, me: permissions };
};

export const useMyPermissions = () => {
  const { loading, me } = useMe();

  if (loading || !me) return { loading, permissions: [] };
  return { loading, permissions: me.permissions || [] };
};

export const useLoaderBase64 = (file: any) => {
  const [base64, setBase64] = useState<any>();
  const reader = new FileReader();
  useEffect(() => {
    reader.onload = () => setBase64(reader.result);
    reader.onerror = () => {};

    reader.readAsDataURL(file.rawFile);
  }, [file]);
  return base64;
};

export const useThrottle = (value: any, limit: number) => {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastRan = useRef(Date.now());

  useEffect(() => {
    const handler = setTimeout(function () {
      if (Date.now() - lastRan.current >= limit) {
        setThrottledValue(value);
        lastRan.current = Date.now();
      }
    }, limit - (Date.now() - lastRan.current));

    return () => {
      clearTimeout(handler);
    };
  }, [value, limit]);

  return throttledValue;
};

export const useCustomResfresh = () => {
  const [refresh, setIncreaseRefresh] = useState(0);

  const setRefresh = useCallback(() => {
    setIncreaseRefresh((count) => count + 1);
  }, []);
  return { refresh, setRefresh };
};

export interface IPermission {
  resource: string;
  action: string;
}

export const useCanAccess = (permission: IPermission | IPermission[]) => {
  const {
    loading,
    permissions,
  }: { loading: boolean; permissions: string[][] } = useMyPermissions();
  if (loading) return false;

  const _pers: IPermission[] = Array.isArray(permission)
    ? permission
    : [permission];

  return _.every(
    _pers,
    (per) =>
      _.findIndex(permissions, (myPer) =>
        _.isEqual(myPer, [per.resource, per.action])
      ) > -1
  );
};

export interface ICan {
  permission: IPermission[] | IPermission;
  TrueComponent: any;
  FalseComponent?: any;
}

export const Can: FC<ICan> = (props) => {
  const { permission, TrueComponent, FalseComponent } = props;
  const canAccess = useCanAccess(permission);
  if (canAccess) return TrueComponent;

  return FalseComponent ? FalseComponent : <></>;
};
